
var arr = 
[
  {
    title: 'الصفحة الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
    per: "home"
  },
  {
    title: 'الزيارات',
    route: 'visits',
    icon: 'TruckIcon',
    full: true
  },
  {
    title: 'المواعيد',
    route: 'calendar',
    icon: 'CalendarIcon',
    full: true
  },
  {
    title: 'المهام',
    route: 'todo',
    icon: 'CheckSquareIcon',
    full: true
  },
  {
    title: 'المستخدمين',
    route: 'users',
    icon: 'UsersIcon',
    per: "home"
  },
  {
    title: 'الطلبات',
    route: 'orders',
    icon: 'ShoppingCartIcon',
    per: "home"
  },
  {
    title: 'سحب الرصيد',
    route: 'withdraw',
    icon: 'DollarSignIcon',
    per: "home"
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon'
  },
]

var user = JSON.parse(localStorage.getItem("user") ?? "{}");

export default arr.filter(function(a){
  if(a.full && user.full){
    return a;
  }else{
    if(!a.full){
      return a;
    }else{
      return false;
    }
  }
})
